import {Link} from "react-router-dom";

export default function CategoryItem({item}) {

    return (
        <div className={'col-md-3 col-6 my-1'}>
            <Link to={`/categories/${item.id}`}>
            <div className=" category-item " >
                <div className="background" style={{backgroundImage: `url(${item.img})`}}></div>
                <img className={'m-auto d-block'} src={item.img} loading={'lazy'} alt=""/>
                <div className={'category-item-overlay text-white text-center py-2'}>{item.name}</div>
            </div>
            </Link>
        </div>

    )
}