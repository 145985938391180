import {useState} from "react";
import {baseApi} from "../../api/axios";
import {useAuthStore} from "../../store/user-store";
import LoadingComponent from "../Loading/LoadingComponent";
import {toast} from "react-toastify";


export default function ProfileComponent() {
    const authStore = useAuthStore()
    const [loading, setLoading] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [name, setName] = useState(authStore.user?.name)
    const [email, setEmail] = useState(authStore.user?.email)
    const [phone, setPhone] = useState(authStore.user?.phone ?? '')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState([])
    const getProfile = async () => {
        setLoading(true)
        try {
            const response = await baseApi('/profile')
            if (response.data?.user) {
                authStore.setUser(response.data?.user)
                localStorage.removeItem('user')
                localStorage.setItem('user', JSON.stringify(response.data?.user))
            }
        } catch (e) {

        }
        setLoading(false)
    }
    const validateEmail = (text) => {
        return text.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const validate = () => {

        let Ok = true;
        if (name.length === 0) {

            setErrors([...errors, 'يرجى إدخال الاسم بشكل صحيح'])
            Ok = false;
        }
        if (!validateEmail(email)) {
            setErrors([...errors, 'يرجى إدخال البريد الإلكتروني بشكل صحيح'])
            Ok = false;
        }
        if (phone.length === 0) {
            setErrors([...errors, 'يرجى إدخال رقم الهاتف بشكل صحيح'])
            Ok = false;
        }
        if (password.length > 0 && password.length < 8) {
            setErrors([...errors, 'يرجى إدخال كلمة مرور أكبر من 8 أحرف '])
            Ok = false;
        }

        return Ok;
    }

    const onSubmit = async () => {
        setErrors([])
        setLoadingSubmit(true)
        if (validate()) {
            try {
                const data = new FormData()
                data.append('name', name)
                data.append('email', email)
                data.append('phone', phone)
                data.append('password', password)
                const response = await baseApi.post('/profile', data)
                if (response.data?.user) {
                    authStore.setUser(response.data?.user)
                    localStorage.removeItem('user')
                    localStorage.setItem('user', JSON.stringify(response.data?.user))
                    toast.success('تم التعديل بنجاح')
                }
            } catch (e) {
                console.log(e.response.data)
                setErrors([e.response.data?.msg])
            }
        }
        setLoadingSubmit(false)
    }
    if (loading) {
        return <div className={'container'}>
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <LoadingComponent/>
                </div>
            </div>
        </div>
    }

    if (!loading && authStore.user != null) {
        return <div className={'container'}>
            <div className="row justify-content-center align-items-center">
                <div className="col-md-8">
                    <h2 className={'text-white'}>أهلا بك!</h2>
                </div>
                <div className="col-md-8">
                    <h2 className={'text-orange'}>تعديل الملف الشخصي</h2>
                </div>
                <div className="col-md-8">

                    <div className="d-flex flex-column justify-content-center align-items-center card-auth"
                         style={{flex: '1 0 auto'}}>
                        <img className={'logo-auth'} src={authStore.settings?.logo} alt=""/>
                        <ul>
                            {errors?.map((el) => <li key={Math.random()} className="text-danger">{el}</li>)}
                        </ul>
                        <span className={'text-orange d-inline-block my-2 small'}>إذا كنت لا تريد تعديل كلمة المرور اترك الحقل فارغا</span>
                        <input type="text" onChange={(e) => setName(e.target.value)}
                               className="input-form my-2" placeholder={'الاسم'} autoComplete={'off'} value={name}/>
                        <input type="email" onChange={(e) => setEmail(e.target.value)}
                               className="input-form my-2" placeholder={'البريد الإلكتروني'} autoComplete={'new-email'}
                               value={email}/>
                        <input type="text" onChange={(e) => setPhone(e.target.value)}
                               className="input-form my-2" placeholder={'رقم الهاتف'} autoComplete={'off'}
                               value={phone}/>
                        <input type="password" onChange={(e) => setPassword(e.target.value)}
                               className="input-form my-2" placeholder={'كلمة المرور'} autoComplete={'new-password'}/>
                        {!loadingSubmit ?
                            <button onClick={onSubmit} className="btn d-block btn-sm submit-btn ">حفظ</button> :
                            <button className="btn d-block btn-sm submit-btn ">جاري المعالجة</button>}
                    </div>
                </div>

            </div>
        </div>
    }

}