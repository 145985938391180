import {useAuthStore} from "../../store/user-store";
import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {baseApi} from "../../api/axios";

export default function PayDefaultProduct({product}) {
    const authStore = useAuthStore()
    const [errors, setErrors] = useState({});

    const [loading, setLoading] = useState('');
    const redirect = useNavigate()
    const onSubmit = async () => {
        setLoading(true)
        setErrors({})
        const data = new FormData();

        data.append('product_id', product.id)
        data.append('count', 1)

        try {
            const response = await baseApi.post('/orders', data)
            if (response.data?.user) {
                authStore.setUser(response.data?.user)
                localStorage.setItem('user', JSON.stringify(response.data?.user))
            }

            redirect('/orders')
        } catch (e) {
            setErrors({...errors, msg: e.response?.data?.msg})
        }
        setLoading(false)


    }
    return (
        <div className="col-md-8 ">
            <div className="form mx-3">
                <p className={'text-orange'}><span>   <i className="fas fa-caret-left text-orange fa-2x"></i></span> {product.name}</p>
                <ul>
                    {/* eslint-disable-next-line array-callback-return */}
                    {Object.entries(errors).map(([key, value], i) => {
                        if (value) {
                            return <li key={i} className={'text-danger'}>
                                {value}
                            </li>
                        }
                    })}
                </ul>
                <div className=" px-2">
                   <span className={'text-orange'}>منتج أكواد يمكنك الطلب بشكل مباشر</span>
                    {!loading ? (
                        authStore.user === null ? (
                            <Link to="/login" className="btn d-block btn-sm submit-btn">تسجيل الدخول</Link>
                        ) : (
                            <button onClick={onSubmit} className="btn d-block btn-sm submit-btn">شراء</button>
                        )
                    ) : (
                        <button className="btn d-block btn-sm submit-btn">جاري الطلب</button>
                    )}


                </div>
            </div>

        </div>
    )
}