import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import {useAuthStore} from "../../store/user-store";

export default function OrderItem({order}) {

    const [item, setItem] = useState(order)
    const [loading, setLoading] = useState(false)
    const authStore = useAuthStore()

    useEffect(() => {
        let timer = setInterval(async () => {
            if (!loading) {
                getStatus();
            }
        }, 15000);

        return () => {
            clearInterval(timer);
        };
    }, [loading]);

    const getStatus = async () => {
        setLoading(true);
        try {
            if (item.is_pending === true) {
                const response = await baseApi.get(`/orders/${order.id}`);
                if (response.data?.order) {
                    if (response.data?.order.is_pending === false) {

                        setItem(response.data?.order);
                        if (response.data?.user) {
                            authStore.setUser(response.data?.user)
                            localStorage.setItem('user', JSON.stringify(response.data?.user))
                        }
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    return (
        <tr>
            <td>{order.id}</td>
            <td>{order.product}</td>
            <td className={'text-blue'}>{order.price.toFixed(2)} $</td>
            <td>
                {order.send_data?.player_id ?
                    <span className={'text-orange'}> ID : {order.send_data?.player_id}</span> : null}
                {order.send_data?.email ?
                    <span className={'text-orange'}> Email : {order.send_data?.email}</span> : null}
                {order.send_data?.password ?
                    <span className={'text-orange'}> Password : {order.send_data?.password}</span> : null}
                {order.send_data?.qty ? <span className={'text-orange'}> الكمية : {order.send_data?.qty}</span> : null}
            </td>
            <td>{item?.receive_data?.msg}</td>
            <td>{item?.status}</td>
            <td>{order.created_at}</td>
        </tr>
    )
}