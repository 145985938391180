import {Link} from "react-router-dom";
import {useAuthStore} from "../../store/user-store";

export default function ProductItem({item}){
const authStore=useAuthStore()
    return (
        <div className={'col-md-3 col-6 my-2'}>
           <div className={'d-flex flex-column product-item  justify-content-between' }>
              <div className={'container-img'}>
                  <div className="background" style={{backgroundImage:`url(${item.img})`}}></div>
                  <img className={'product-img'} loading={'lazy'} src={item.img} alt={item.name}/>
              </div>
               <p className={'text-white'}>{item.name}</p>
               <div className={'d-flex flex-row justify-content-around align-items-center'} style={{gap:'30px'}}>
                   <span className={'text-orange'}>{item.price} {authStore.icon? <i className="fa-solid fa-coins"></i>:'$'}</span>
                   <Link to={`/products/${item.id}`} className={'btn btn-sm orange-btn py-1 px-3'}>شراء</Link>
               </div>
           </div>
        </div>
    )
}