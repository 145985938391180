import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import LoadingComponent from "../Loading/LoadingComponent";
import OrderItem from "../OrderSections/OrderItem";

export default function BalanceSection(){
    const [loading,setLoading]=useState(false)
    const [balances,setBalances]=useState([])
    const [error,seterror]=useState([])
    const [page, setPage] = useState(1)
    const [nextPage, setNextPage] = useState(null)
    const [prevPage, setPrevPage] = useState(null)
    const nextPageNum = () => {
        setPage(page + 1)
    }
    const prevPageNum = () => {
        if(page>1){
            setPage(page - 1)
        }

    }
    const getBalances = async () => {
        setLoading(true)
        try {
            const response = await baseApi(`/balances?page=${page}`)
            setBalances(response.data?.balances)
            setNextPage(response.data?.next)
            setPrevPage(response.data?.back)
        } catch (e) {
            console.log('ERROR', e.response)
        }
        setLoading(false)
    }
    useEffect(() => {
        getBalances()
    }, [page])
    if (loading) {
        return <div className={'container'}>
            <div className="row justify-content-center">
                <div className="col-6">
                    <LoadingComponent/>
                </div>
            </div>
        </div>
    }else if (!loading && balances.length==0) {
        return <div className={'container'}>
            <div className="row justify-content-center">
                <div className="col-6">
                    <h6 className="alert alert-danger">لا يوجد بيانات لعرضها</h6>
                </div>
            </div>
        </div>
    }else{
        return <div className={'container'}>
            <div className="row justify-content-center">
                <div className="col-md-9">
                    <div className="table-responsive">
                        <table className={`table table-striped order-table table-hover table-success`}>
                            <thead>
                            <tr>
                                <th>إيداع</th>
                                <th>سحب</th>
                                <th>الرصيد</th>
                                <th>بيانات </th>
                                <th>وقت العملية</th>
                            </tr>
                            </thead>
                            <tbody>
                            {balances.length > 0 ? balances.map((el) => {
                                return <tr key={Math.random()}>
                                    <td>{el.credit.toFixed(2)}</td>
                                    <td>{el.debit.toFixed(2)}</td>
                                    <td>{el.total.toFixed(2)}</td>
                                    <td>{el.info} </td>
                                    <td>{el.created_at}</td>
                                </tr>
                            }) : null}
                            </tbody>
                        </table>

                    </div>
                    <div className="container">
                        <div className="col-12 text-center">
                            {prevPage!==null? <button onClick={prevPageNum} className="btn-sm submit-btn">التالي</button>:null}
                            {nextPage!==null? <button onClick={nextPageNum} className="btn-sm submit-btn mx-1">السابق</button>:null}


                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}