import {useState} from "react";
import {useAuthStore} from "../../store/user-store";
import {Link, useNavigate} from "react-router-dom";
import {baseApi} from "../../api/axios";
import {toast} from "react-toastify";

export default function OnlineItemComponent({item}) {

    const [loading, setLoading] = useState(false)
    const authStore = useAuthStore()
    const redirect = useNavigate()
    const submit = async () => {
        setLoading(true)

        try {
            const data = new FormData()
            data.append('app_id', item.id)
            const response = await baseApi.post(`/online`, data)
            if (response.data?.user?.id) {
                authStore.setUser(response.data?.user)
                localStorage.removeItem('user')
                localStorage.setItem('user', JSON.stringify(response.data?.user))
                toast.success('تم شراء الرقم');
                redirect('/invoices')
            }
        } catch (e) {
            toast.warning(e.response.data?.error)
        }

        setLoading(false)


    }
    return (
        <div className="col-md-2 col-4 text-center">
            <div className={'d-flex flex-column '}>
                <div className={''}>
                    <img className={'rounded online-img'} src={item.img} alt=""/>
                </div>
                <p className={'text-orange'}>{item.price.toFixed(2)} $</p>
                <p className={'text-white'}>{item.name}</p>
                {authStore.user?.id && !loading ?
                    <button className={'btn btn-sm btn-success mb-3'} onClick={submit}>شراء</button> : null}
                {loading ? <button className={'btn btn-sm btn-success mb-3'}>جاري إرسال الطلب</button> : null}
            </div>
        </div>
    )
}